const blended_tabs = [
    "Course Info", 
    "Synchronous",
    "Module Editor",
    "Attendees"
]

const e_learning_tabs = [
    "Course Info", 
    "Module Editor",
]

// export default [
//     "Course Info", 
//     "Synchronous",
//     "Module Editor"
// ]

export { blended_tabs, e_learning_tabs }