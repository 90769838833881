<template>
  <v-dialog  persistent v-model="dialog" max-width="400">
    <v-card v-if="evaluation">
      <v-card-title class="d-flex align-center justify-space-between">
        <h4 class="primary--text poppins fw600">Lock Until</h4>
        <v-btn icon @click="$emit('close')">
          <v-icon size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-checkbox v-model="valid_until" value="anytime" label="Accessible anytime" class="roboto fw500" hide-details/>
        <v-checkbox v-model="valid_until" :value="dated" label="Lock until" class="roboto fw500" />
        <v-menu
          :disabled="valid_until==='anytime'"
          ref="menu"
          v-model="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatted"
              placeholder="Select date"
              outlined
              dense
              class="general-custom-field roboto f14 secondary-1--text fw500"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="date" no-title scrollable />
        </v-menu>
        <div class="font-italic f14 roboto secondary--text" v-if="dateFormatted">
          This evaluation will be accessible by {{dateFormatted}} 
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="d-flex align-center justify-end">
        <v-btn class="text-capitalize poppins" text @click="$emit('close')">Cancel</v-btn>
        <v-btn class="text-capitalize poppins" color="primary" v-if="!saving" @click="save">Save Changes</v-btn>
        <v-btn class="text-capitalize poppins" color="primary" v-else >Saving...</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </template>
  
  <script>
  import moment from 'moment'
  import { mapActions, mapMutations } from 'vuex'
  
  export default {
    props: ['dialog', 'evaluation'],
  
    data: () => ({
      date: null,
      menu: false,
      dated: null,
      saving: false,
      valid_until: ''
    }),
  
    computed: {
      dateFormatted() {
        return this.date ? moment(this.date).format('LL') : ''
      },
    },
    methods: {
      ...mapActions('instructor', ['courseEvaluationValidityAction', 'moduleEvaluationValidityAction', 'getCourseEvaluationsAction', 'getModuleEvaluationsAction']),
      ...mapMutations(['alertMutation']),
  
      save() {
        this.saving=true

        if(this.evaluation.module_id) {
          this.moduleEvaluationValidityAction({
            id: this.evaluation.id,
            module_id: this.evaluation.module_id,
            date: this.date === null ? 'anytime' : this.date
          }).then(() => {
            this.getModuleEvaluationsAction(this.evaluation.module_id).then(() => {
              this.$emit('close')
              if(this.date) {
                this.alertMutation({
                  show: true,
                  text: `Module Evaluation is locked until ${this.dateFormatted}.`,
                  type: "success"
                })
              } else {
                this.alertMutation({
                  show: true,
                  text: `Evaluation is accessible anytime.`,
                  type: "success"
                })
              }
              this.saving=false
            }).catch(() => {
              this.saving=false
            })
          })
          .catch(() => {
            this.saving=false
          })
        }
        if(this.evaluation.course_id) {
          this.courseEvaluationValidityAction({
            id: this.evaluation.id,
            course_id: this.evaluation.course_id,
            date: this.date === null ? 'anytime' : this.date
          }).then(() => {
            this.getCourseEvaluationsAction(this.evaluation.course_id).then(() => {
              this.$emit('close')
              if(this.date) {
                this.alertMutation({
                  show: true,
                  text: `Course Evaluation is locked until ${this.dateFormatted}.`,
                  type: "success"
                })
              } else {
                this.alertMutation({
                  show: true,
                  text: `Course Evaluation is accessible anytime.`,
                  type: "success"
                })
              }
              this.saving=false
            }).catch(() => {
              this.saving=false
            })
            
          })
          .catch(() => {
            this.saving=false
          })
        }
      }
    },
    watch: {

      valid_until(val) {
        if(val === 'anytime') {
          this.date = null
        } else {
          this.date = new Date().toISOString().substr(0, 10)
        }
      },

      evaluation(val) {
        if(val) {
          if(val.valid_until === 'anytime') {
            this.date=null
            this.dated='nodate'
          } else {
            this.dated=this.evaluation.valid_until
            this.date=this.evaluation.valid_until
          }

          this.valid_until = this.evaluation.valid_until
        }
      },

      dialog(val) {
        if(val == true) {
          if(this.evaluation.valid_until==='anytime') return this.dated='nodate', this.date=null
          
          return this.dated=this.evaluation.valid_until, this.date=this.evaluation.valid_until
        }
      }
    }
  }
  </script>