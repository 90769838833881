<template>
    <v-dialog persistent v-model="dialog" max-width="530">
        <v-card rounded="lg">
            <v-card-text>
				<v-btn icon absolute right class="pt-4"  @click="$emit('close')">
					<v-icon>mdi-close</v-icon>
				</v-btn>
                <div class="pt-4 py-5 f14 poppins fw500">Enrollee Count: {{ this.count }}</div>
                <!-- <v-select
                    outlined
                    dense
                    class="general-custom-field roboto f14 secondary-1--text fw500"
                    :items="class_categories"
                    item-value="id"
                    multiple
                    hide-details
                >
                    <template slot="item" slot-scope="data">
                    <v-list-item-content :name="data.item.class_name">
                        <v-list-item-title class="poppins f14 fw500">
                        <span class="secondary--text">{{ data.item.class_code }}-</span>
                        <span class="secondary-3--text">{{ data.item.class_name }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                    </template>
                    <template slot="selection" slot-scope="data">
                    <div class="poppins f14 fw500 my-1">
                        <span class="secondary--text">{{ data.item.class_code }}-</span>
                        <span class="secondary-3--text">{{ data.item.class_name }},&nbsp; </span>
                    </div>
                    </template>
                </v-select> -->
                <v-text-field 
                    filled 
                    class="noline general-custom-field roboto f14 secondary-1--text fw500" 
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search Enrollee"
                    hide-details
                    v-model="search"
                />
                
                <v-sheet height="350" class="overflow-y-auto scroller">
                    <v-data-table
                        :headers="headers"
                        :items="enrollees"
                        :search="search"
                    />
                </v-sheet>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    props: ['dialog', 'enrollees', 'count', 'class_categories'],
    data: () => ({
        headers: [
            {
                text: 'Enrollee',
                value: 'name'
            },
            {
                text: 'Date Enrolled',
                value: 'date_enrolled'
            },
        ],
        search: ''
    })
}
</script>