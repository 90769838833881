import { render, staticRenderFns } from "./AssessmentShareDialog.vue?vue&type=template&id=b6815856"
import script from "./AssessmentShareDialog.vue?vue&type=script&lang=js"
export * from "./AssessmentShareDialog.vue?vue&type=script&lang=js"
import style0 from "./AssessmentShareDialog.vue?vue&type=style&index=0&id=b6815856&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports