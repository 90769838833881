var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('section',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-xl-6 col-lg-8"},[_c('label',{staticClass:"poppins fw500 f12 secondary--text mb-2 align-start"},[_vm._v("TOPIC *")]),(_vm.showMce1)?_c('editor',{staticClass:"tiny",attrs:{"api-key":_vm.mce_key,"init":{
                    height: 200,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor table',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                        'formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
                        bullist numlist outdent indent | removeformat | help',
                    content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }},on:{"input":_vm.updateEvaluation}}):_c('circular')],1),_c('div',{staticClass:"col-xl-6 col-lg-4"},[_c('label',{staticClass:"poppins fw500 f12 secondary-2--text mb-2"},[_vm._v("DESCRIPTION *")]),(_vm.showMce2)?_c('editor',{staticClass:"tiny",attrs:{"api-key":_vm.mce_key,"init":{
                    height: 200,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                    'formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                    content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }},on:{"input":_vm.updateEvaluation}}):_c('circular')],1),_c('v-divider'),_c('div',{staticClass:"col-xl-6 col-lg-4"},[_c('v-btn',{staticClass:"f12 text-capitalize primary-font",attrs:{"dense":"","text":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-image ")]),_vm._v(" Insert Image ")],1)],1)],1):_c('circular')
}
var staticRenderFns = []

export { render, staticRenderFns }