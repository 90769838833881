<template>
  <v-dialog  persistent v-model="dialog" max-width="400">
    <v-card v-if="module">
      <v-card-title class="d-flex align-center justify-space-between">
        <h4 class="primary--text poppins fw600">Lock Module</h4>
        <v-btn icon @click="$emit('close')">
          <v-icon size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-checkbox v-model="valid_until" value="anytime" label="Accessible anytime" class="roboto fw500" hide-details/>
        <v-checkbox v-if="index > 0 || valid_until === 'lock'" v-model="valid_until" value="lock" label="Lock module if preceding module is incomplete." class="roboto fw500" hide-details/>
        <v-checkbox v-model="valid_until" :value="dated" label="Lock until" class="roboto fw500" />

        <v-menu
          :disabled="valid_until==='anytime'"
          ref="menu"
          v-model="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatted"
              placeholder="Select date"
              outlined
              dense
              class="general-custom-field roboto f14 secondary-1--text fw500"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="date" no-title scrollable />
        </v-menu>
        <div class="font-italic f14 roboto secondary--text" v-if="dateFormatted">
          This module will be accessible by {{dateFormatted}} 
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="d-flex align-center justify-end">
        <v-btn class="text-capitalize poppins" text @click="$emit('close')">Cancel</v-btn>
        <v-btn class="text-capitalize poppins" color="primary" v-if="!saving" @click="save">Save Changes</v-btn>
        <v-btn class="text-capitalize poppins" color="primary" v-else >Saving...</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: ['dialog', 'module'],

  data: () => ({
    date: null,
    menu: false,
    dated: null,
    saving: false,
    valid_until: ''
  }),

  computed: {
    ...mapState('instructor', {
      'modules': (state) => state.modules
    }),

    dateFormatted() {
      return this.date ? moment(this.date).format('LL') : ''
    },

    index() {
      if(module) {
        return this.modules.findIndex(item => item.id === this.module.id)
      }

      return -1
    }
  },
  methods: {
    ...mapActions('instructor', ['moduleValidityAction']),
    ...mapMutations(['alertMutation']),

    save() {
      this.saving=true
      this.moduleValidityAction({
        id: this.module.id,
        course_id: this.module.course_id,
        date: this.date === null ? this.valid_until : this.date
      }).then(() => {
        this.$emit('close')
        this.$emit('success')
        this.alertMutation({
          show: true,
          text: this.date ? `Module is locked until ${this.dateFormatted}.` : (this.valid_until === 'anytime' ? `Module is accessible anytime.` : (this.index > 0 ? `Module is accessible after ${this.modules[this.index - 1].title} is completed` : `Module updated!`)),
          type: "success"
        })
        this.saving=false
      }).catch(() => {
        this.saving=false
      })
    }
  },
  watch: {

    valid_until(val) {
      if(['anytime', 'lock'].includes(val)) {
        this.date = null
      } else {
        this.date = new Date().toISOString().substr(0, 10)
      }
    },

    module(val) {
      if(val) {
        if(['anytime', 'lock'].includes(val.valid_until)) {
          this.date=null
          this.dated='nodate'
        } else {
          this.dated=this.module.valid_until
          this.date=this.module.valid_until
        }

        this.valid_until = this.module.valid_until
      }
    },

    dialog(val) {
      if(val == true) {
        if(['anytime', 'lock'].includes(this.module.valid_until)) return this.dated='nodate', this.date=null
        
        return this.dated=this.module.valid_until, this.date=this.module.valid_until
      }
    }
  }
}
</script>